@import './Partials/mixins';
@import './Partials/colors';
@import './chat';

body {
  font-family: 'GT Walsheim Pro';
}

.bg-image {
  background-image: linear-gradient(to top, #0b0c0f 0%, rgba(0, 0, 0, 0) 100%), url('https://image.tmdb.org/t/p/original/nDOIsgCYZqEMvomSR6t9QUIPZJS.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  align-items: flex-end;
}

.input-group-text {
  background-color: $white;
  font-weight: 300;
  border-left: 0;
  cursor: pointer;
  color: $border-light;
  border-radius: 0;
  display: inline;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

form {
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

textarea {
  flex-grow: 1;
}

.input-group {
  padding-right: 15px;
  justify-content: space-between;
}

textarea {
  padding: 12px 20px;
  font-weight: 300;
  display: inline-block;
  border: 1px solid $border-light;
  border-radius: 0px;
  box-sizing: border-box;
  color: $border-light;
  resize: none;
  background-color: $white;
}

textarea::-moz-placeholder {
  font-weight: 300;
  color: $border-light;
}

input::placeholder {
  font-weight: 300;
  color: $border-light;
}

.bg-light {
  height: 100vh;
  overflow: hidden;
}

// .bg-light::before {
//   content: '';
//   display: block;
//   position: absolute;
//   left: 0;
//   bottom: 65px;
//   width: 100%;
//   opacity: 0.01;
//   height: 100%;
//   background-image: url(https://static.skuflic.com/images/branding/logo/dark.svg);
//   background-repeat: no-repeat;
//   background-position: bottom;
//   background-size: contain;
//   opacity: 0.01;
// }

.Toastify__toast-body>div:last-child {
  font-size: 13px !important;
  font-weight: 100 !important;
}

.header {
  height: fit-content;
}

.emoji-picker {
  margin-bottom: 10px;
}

.legal {
  margin-bottom: 0px;
  font-size: 10px;
  opacity: 0.5;
  color: $border-light;

  p {
    color: $border-light;
    font-weight: 300;
    margin-bottom: 5px;
  }

  a {
    font-weight: 300;
    color: $border-light;
    text-decoration: none;
    border-right: 1px solid $border-light;
    margin-right: 5px;
    padding-right: 5px;
    font-size: 10px;

    &:nth-child(3) {
      border: none;
      margin: 0;
      padding: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    font-size: 8px;
    letter-spacing: 0px;
    line-height: 10px;
    font-weight: 100;
    text-align: justify;
    display: block;
  }
}

.loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 90vh;
}

.warning {
  .accept {
    color: $very-light-opacity-gray;
    cursor: pointer;
    border-top: 1px solid $light-opacity-gray;
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 0px;
    padding: 10px 20px 10px 20px;
    text-align: right;
    text-transform: uppercase;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    background: rgb(35, 36, 38);
    color: $white;

    p {
      padding: 20px;
      margin: 0;
      font-size: 11px;
      letter-spacing: 0px;
      font-weight: 100;
      text-align: justify;
    }
  }
}