.chat {
    z-index: 1;
    position: relative;

    .list-of-messages {
        list-style: none;
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 400;
        padding-left: 0px;
        overflow: scroll;
        height: 90vh;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .content {
        max-width: 95%;
        font-weight: 300;
        text-align: left;
        border-radius: 20px;
        padding: 10px;
        width: fit-content;
        white-space: pre-line;
    }

    .guest {
        .timestamp {
            text-align: left;
        }

        .content {
            list-style: none;
            background-color: $white-light;
            color: $gray;
            border-top-left-radius: 0px;
        }
    }

    .timestamp {
        font-size: 11px;
        color: $gray;
        font-weight: 100;
        margin-bottom: 3px;
    }

    .currentMember {
        right: 0;
        direction: rtl;

        .username {
            text-align: right;
        }

        .timestamp {
            text-align: right;
            direction: ltr;
        }

        .content {
            list-style: none;
            background-color: #007df9;
            color: white;
            border-top-right-radius: 0px;
            direction: ltr;
        }
    }
}

form {
    position: fixed;
    bottom: 0;
    padding-bottom: 15px;
}

li {
    margin-bottom: 20px;

    &:first-child {
        margin-top: 30px;
    }
}

.online-members {
    list-style: none;
    padding: 0px;
    font-size: 13px;

    span {
        color: #343a3f;
        font-weight: 200;
        margin-bottom: 0px;
        font-size: 11px;
    }
}

@include for-phone {
    .emoji {
        display: none !important;
    }
}

em-emoji-picker {
    --rgb-accent: 206, 212, 218;
}