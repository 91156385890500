@mixin for-phone {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin for-desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop {
    @media (min-width: 1800px) {
        @content;
    }
}
